import * as Sentry from "@sentry/react";
import { useUnknownErrorModal } from "./Modal/BasicDialogContent";




// type BaseHandler = {
//     [type: string]: (msg: string) => void;
// };

// type HandlersWithSuccessAndUnhandled = {
//     success: (response: JSON) => void;
//     unhandled: UnhandledFallbackAction;
// } & BaseHandler;

// type Handlers = 
//     | HandlersWithSuccessAndUnhandled 
//     | BaseHandler;



export const useFetch = () => {
    const error = useUnknownErrorModal();


    /**
     * Vanilla fetch wrapper with sentry metrics and logging + error handling.
     * @param url Url.  Passed straight to fetch()
     * @param options Method, body, etc.  Passed straight to fetch()
     * @param handlers Success and error handlers.
     * If no success handler is included, it is assumed to be a background task and will fail silently, unless an unhandled then it will show a modal
     * Success anyway would be used for like setting email on kiosk.  We want to see if there are any input errors to show email conflicts, but if any weird errors or fail to fetch them just say success.
     * Dismiss would be used for like saving changes made to projects in the dashboard.  Users will have to just try again.  Changes should not be cleared from the ui tho.
     * Reload Button would be used for like loading dashboard info.  Users will just have to reload and see if it works.
     * Auto reload would be used for loading display or project data on kiosk.  It should show an error in the meantime and then reload without interaction.
     * Kiosk restart would be used to navigate back to the kiosk welcome page.
     */
    const fetchData = (
        url: string,
        options: any,
        handlers: any, // TODO type
        retryNo: number = 0
    ) => {

        // Fetch
        fetch(url, options)

        // Success
        .then(async (response) => {
            // Allow removing loading anim on forms.
            if (handlers.finished_signal) handlers.finished_signal();

            // Weird behaviour, maybe fixed?
            if (response == undefined) {
                Sentry.captureException("[RESPONSE UNDEFINED] This should never happen!!!")
            }

            // Success
            if (response.ok) { // Note: These have to be nested because we dont want to go to else if when no success handler exists
                if (handlers.success) handlers.success(response);
            }

            // Input error
            else if (response.status == 400) {
                const json: any = await response.json();

                // Handled input error
                if (handlers["input_"+json.error.type]) {

                    // Metric to keep track of normal and expected errors.  These are not an issue unless there is a weird spike.
                    // TODO

                    // Return to handler
                    handlers["input_"+json.error.type](json.error);
                }

                // Unhandled input error
                else {

                    // Message to actually alert and have additional details + replay.
                    Sentry.captureException(`Unhandled Input Error | Type: (${json.error.type}) | Msg: (${json.error.message})`);

                    // Success anyway (silent fail)
                    if (handlers.unhandled == "success_anyway" && handlers.success) handlers.success();

                    // Show error modal
                    else if (handlers.unhandled) {
                        error(
                            handlers.unhandled,
                            {
                                type: "input",
                                cat: json.error.type,
                                title: json.error.title,
                                msg: json.error.message
                            }
                        )
                    }

                }
            }

            
            // Unknown error
            else {

                const text = await response.text();
                
                // Message to actually alert and have additional details + replay.
                Sentry.captureException(`Unhandled Code Error | Code: (${response.status}) | Url: (${url}) | Msg: (${text})`);

                // Success anyway (silent fail)
                if (handlers.unhandled == "success_anyway" && handlers.success) handlers.success();

                // Show error modal
                else if (handlers.unhandled) {
                    error(
                        handlers.unhandled,
                        {
                            type: "code",
                            code: response.status,
                            text: text
                        }
                    )
                }

            }
        })


        // Failed to fetch
        .catch(() => {

            console.log("Failed to fetch error!  Current retries " + retryNo)

            // Metric to keep track of failed to fetch errors.
            // TODO

            // Auto retry
            if (retryNo >= 3) { // Finnaly fail (after 3 retries) :(
                console.log("-> Given up.")

                // Message to actually alert
                Sentry.captureMessage(`Failed To Fetch (after retries) | Url: (${url})`);

                // Allow removing loading anim on forms.
                if (handlers.finished_signal) handlers.finished_signal();

                // Success anyway (silent fail)
                // TODO shouldnt we just call success before waiting if it doesn't matter?
                if (handlers.unhandled == "success_anyway" && handlers.success) handlers.success();

                // Show error modal
                else if (handlers.unhandled) {
                    error(
                        handlers.unhandled,
                        {
                            type: "failed_fetch",
                            url: url
                        }
                    )
                }

            }

            // Retry again
            else {
                console.log("-> Going to retry...")
                setTimeout(() => {
                    console.log("-> Retrying...")
                    fetchData(url, options, handlers, retryNo + 1)
                }, (retryNo + 1) * 1000) // Exponential backoff (+1 so it doesnt *0)
                // TODO some way to say (taking longer than expected) or smth?
            }

            return; // This return is useless, it needs to be the one checking if response == undefined below.

        })

    };

    return fetchData;

}


export type ErrorType = {
    type: string,
    title?: string,
    message: string,
}