import { ReactNode, useContext, useEffect, useState } from "react";
import { ModalContext } from "./ModalContextProvider";
import diagStyles from "./basicDialogContent.module.scss";
import React from "react";
import _ from "lodash";
import { UnhandledFallbackAction } from "../utils";
import { useNavigate } from "react-router-dom";



export const useUnknownErrorModal = () => {
    const { createModal, closeModal } = useContext(ModalContext);
	const navigate = useNavigate();

	const [ time, setTime ] = useState<number>(5);

    const open = async (
		unhandledFallbackAction: UnhandledFallbackAction,
		details:
			{
				type: "input",
				cat: string,
				title: string,
				msg: string
			}
			|
			{
				type: "code",
				code: number,
				text: string
			}
			|
			{
				type: "failed_fetch",
				url: string
			}
		) => {

			// Count down and reload
			if (unhandledFallbackAction == "auto_reload_5s") {
				setTime(5);
				const int = setInterval(() => {
					setTime((t) => t-1);
					if (time-1 <= 0) {
						clearInterval(int);
						window.location.reload();
					}
				}, 1000);
			}

			createModal(
				unhandledFallbackAction == "dismiss",
				false,
				<BasicDialogContent
					hideX={unhandledFallbackAction != "dismiss"}
					headerIcon="fa-solid fa-bug"
					headerText="Unexpected Error"
					headerBgColor="red"
					headerTextColor="white"
					lineColor="white"
				>
			
					{(details.type == "input" ?
						<h1>{details.title}</h1>
					: 
						<h1>Uh Oh!</h1>
					)}
					

					{/* What happened */}
					{details.type == "failed_fetch" && 
						<p>A network error has occured.</p>
					}

					{details.type == "input" && 
						<p>{details.msg}</p>
					}

					{details.type == "code" && 
						<p>Something went wrong, and we don't know what it was.</p>
					}

					<br/>
					<br/>


					<p>This issue has been automatically reported.</p>


					{/* What to do */}
					{(unhandledFallbackAction == "dismiss" || unhandledFallbackAction == "kiosk_restart") && 
						<p>Please try again.</p>
					}

					{unhandledFallbackAction == "reload_btn" && 
						<p>Please reload the page and try again.</p>
					}

					{unhandledFallbackAction == "auto_reload_5s" && 
						<p>Please wait.</p>
					}

					<br/>
					<br/>
								
			
					{/* Details */}
					<p className={diagStyles.detail}>Type: <span>{details.type}</span></p>
					{details.type == "input" && <>
						<p className={diagStyles.detail}>Category: <span>{details.cat}</span></p>
					</>}
					{details.type == "code" && <>
						<p className={diagStyles.detail}>Code: <span>{details.code}</span></p>
						{/* Cloudflare 500 error responses are html, so contain a wall of useless characters */}
						{details.code != 500 && <p className={diagStyles.detail}>Response: <span>{details.text}</span></p>}
					</>}
					{details.type == "failed_fetch" && <>
						<p className={diagStyles.detail}>URL: <span>{details.url}</span></p>
					</>}

					<br/>
					<br/>


					<div className={diagStyles.buttons}>

						{/* Buttons */}
						{unhandledFallbackAction == "kiosk_restart" &&
							<button
								className="basic-button"
								onClick={() => {
									navigate("/kiosk");
									closeModal();
								}}
							>
								Restart
							</button>
						}

						{unhandledFallbackAction == "dismiss" &&
							<button
								className="basic-button"
								onClick={() => {
									closeModal();
								}}
							>
								Ok :(
							</button>
						}

						{unhandledFallbackAction == "reload_btn" &&
							<button
								className="basic-button"
								onClick={() => {
									window.location.reload()
								}}
							>
								Reload
							</button>
						}

						{unhandledFallbackAction == "auto_reload_5s" &&
							<button disabled>
								Reloading in {time}s
							</button>
						}

					</div>
			
				</BasicDialogContent>
			)
	}

	return open;
}


export default function BasicDialogContent(props: {
	hideX?: boolean,
	headerIcon?: string,
	headerText?: string,
	headerBgColor?: string,
	headerTextColor?: string,
	lineColor?: string,
	children: ReactNode }) {
	const { closeModal } = useContext(ModalContext);

	useEffect(() => {

		function handle(evt: KeyboardEvent) {
			if (evt.key == "Escape") closeModal();
		}

		window.addEventListener("keydown", handle)
		return () => window.removeEventListener("keydown", handle)
	}, [])

    return (
		<div className={diagStyles.container}>

			{props.headerText &&
				<div className={diagStyles.header} style={{ backgroundColor: props.headerBgColor, color: props.headerTextColor, borderBottomColor: props.lineColor }}>

					{props.headerIcon && <i className={props.headerIcon + " " + diagStyles.icon}></i>}

					{props.headerText}

					{!props.hideX && <i className={"fa-solid fa-xmark " + diagStyles.x} style={{ color: props.headerTextColor }} onClick={() => closeModal()}></i>}

				</div>
			}

			<div className={diagStyles.body}>

				{!props.hideX && !props.headerText && <i className={"fa-solid fa-xmark " + diagStyles.x} onClick={() => closeModal()}></i>}

				{props.children}

			</div>

		</div>
	)
}


export function DialogAgreementSection(props: { boxes: string[], setChecked: (checked: boolean) => void }) {
	const [ agreed, setAgreed ] = useState(Array(props.boxes.length).fill(false))

	return (
		<div className={diagStyles.agreementSection}>
			<p className={diagStyles.confirmTitle}>Please confirm:</p>
			{props.boxes.map((text, i) => (
				<div className={diagStyles.agree} onClick={() => toggle(i)}>
				<div className={`${diagStyles.checkMark} ${(agreed[i] ? diagStyles.checked : "")}`}>
					<div className={diagStyles.indicator}/>
				</div>
				<p>{text}</p>
			</div>
			))}
		</div>
	)

	function toggle(i: number) {
		const newAgreed = _.clone(agreed);
		newAgreed[i] = !newAgreed[i];
		setAgreed(newAgreed);

		// Set the overall state if they are all true or false
		props.setChecked(newAgreed.every(v => v === true))
	}

}